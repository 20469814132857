<template>
  <div class="perfor-analyse">
    <!-- start 条件过滤区域 -->
    <div class="df-row">
      <!-- 门店选择器 -->
      <div v-if="authType == 'H'" class="df-col">
        <el-select
          style="width: 260px;"
          v-model="submitData.StoresGuids"
          multiple
          collapse-tags
          size="mini"
          placeholder="请选择门店"
          @change="onChangeStore"
        >
          <el-option
            v-for="item in storesData"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 员工选择器 -->
      <div class="df-col">
        <DfEmplSelect
          ref="DfEmplSelect"
          size="mini"
          :storesGuids="submitData.StoresGuids"
          :emplGuids="submitData.EmplGuids"
          :storeList="storeList"
          :menuMultiple="true"
          :dutyMultiple="true"
          :emplMultiple="true"
          @selected="onSelectedEmpl"
        ></DfEmplSelect>
      </div>

      <!-- 显示内容单选框 -->
      <div class="df-col">
        <span>显示内容：</span>
        <el-radio-group v-model="submitData.QueryMode" @input="onChangeRadio">
          <el-radio :label="1">
            数据对比
          </el-radio>
          <el-radio :label="2">
            趋势(按月)
          </el-radio>
          <el-radio :label="3">
            趋势(按日)
          </el-radio>
        </el-radio-group>
      </div>

      <!-- 日期选择器 -->
      <div class="df-col">
        <el-date-picker
          v-show="submitData.QueryMode == 1"
          v-model="dataArray"
          type="daterange"
          size="mini"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onChangeDate"
        >
        </el-date-picker>

        <el-date-picker
          v-show="submitData.QueryMode == 2"
          v-model="monthArray"
          type="monthrange"
          size="mini"
          value-format="yyyy-MM"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="onChangeDate"
        >
        </el-date-picker>

        <el-date-picker
          v-show="submitData.QueryMode == 3"
          v-model="dayArray"
          type="daterange"
          size="mini"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <!-- 自定义表格按钮 -->
      <div class="df-col">
        <el-button @click="onCustomTable" size="mini" type="primary"
          >自定义表格</el-button
        >
      </div>

      <!-- 查询按钮 -->
      <div class="df-col">
        <el-button @click="onSearchData" size="mini" type="primary"
          >开始查询</el-button
        >
      </div>

      <!-- 导出表格按钮 -->
      <div class="df-col">
        <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
          >导出表格</el-button
        >
      </div>

      <!-- 打印数据按钮 -->
      <div class="df-col">
        <el-button @click="onPrintTable" size="mini" type="primary"
          >打印数据</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区域 -->

    <!-- start 员工业绩分析报表 -->
    <div class="print">
      <el-table
        id="exprot-table"
        :data="dataList"
        v-loading="loading"
        :max-height="tableMaxHeight"
        show-summary
        stripe
        fit
        style="width: 100%"
      >
        <el-table-column
          v-if="item.TopCode != 'Perfor'"
          v-for="(item, index) in headList"
          :key="'a' + index"
          :label="item.Name"
          width="140"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row[item.DataKey] || "--" }}
          </template>
        </el-table-column>

        <!-- 合并列表头(一级表头) -->
        <el-table-column
          :label="top.Name"
          v-for="(top, topIndex) in topList"
          :key="topIndex"
          show-overflow-tooltip
        >
          <template v-for="(list, listIndex) in headList">
            <!-- 子表头(二级表头) -->
            <el-table-column
              v-if="top.Code == list.TopCode"
              :key="list.DataKey"
              :label="list.Name"
              :prop="list.DataKey"
              sortable
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row[list.DataKey] ? scope.row[list.DataKey] : "--" }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- end 员工业绩分析报表 -->

    <!-- start 自定义表格对话框 -->
    <CustomDialog
      ref="customDialog"
      @onConfirm="onSubmitRevenue"
    ></CustomDialog>
    <!-- end 自定义表格对话框 -->
  </div>
</template>

<script>
import Report from "@/api/report.js";
import CustomDialog from "@/views/report/components/custom-dialog.vue";
import printJS from "print-js";

export default {
  components: { CustomDialog },

  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      authType: "", // 当前账号等级 H 总店 M 各门店
      storesData: [], // 门店列表
      // typeSelector: [], // 营收类型列表
      storeList: [], // 门店员工列表
      copyStoreList: [], // 门店员工列表拷贝
      modeSelector: [], // 显示内容列表
      emplSelectedList: [],
      submitData: {
        // 提交数据
        StoresGuids: [], // 门店id
        EmplGuids: [], // 员工id
        QueryMode: 3, // 查询显示内容
        BeginDate: "", // 开始时间
        EndDate: "", // 结束时间
      },
      headData: {}, // 自定义表头源数据
      heads: [], // 自定义表头
      dataArray: [], // 日期范围选择数据
      monthArray: [], // 月份选择数据
      dayArray: [], // 日选择数据
      topList: [], // 一级表头数据
      headList: [], // 二级表头数据
      dataList: [], // 表格数据
      loading: false, // 表格加载
      isFixedRow: true, // 固定列
      excelDisabled: false, // 导出禁用
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.storesData = accountInfo.manageStoresList;

    await this.initDateState()
    await this.initPageData();
    await this.fetchEmplPerforAnalyse();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 72);
    },

    // 初始化页面数据
    async initPageData() {
      try {
        let { data } = await Report.initEmplPerforAnalyse();
        let { modeSelector, StoreList } = data.emplSelector;

        // this.modeSelector = modeSelector;
        this.storeList = StoreList;
        this.copyStoreList = JSON.parse(JSON.stringify(StoreList));
      } catch (e) {
        //TODO handle the exception
      }
    },

    // 获取自定义表格数据
    async fetchEmplPerforAnalyse() {
      try {
        let { data } = await Report.fetchEmplPerforAnalyse();
        let { Heads } = data;
        this.headData = data;
        this.heads = Heads;
      } catch (e) {}
    },

    // 获取员工业绩分析数据
    async queryEmplPerforAnalyse() {
      this.loading = true;
      try {
        let { data } = await Report.queryEmplPerforAnalyse(this.submitData);
        let { DataList, HeadList, TopList } = data;
        this.topList = TopList;
        this.headList = HeadList;
        this.dataList = DataList;
      } catch (e) {
        //TODO handle the exception
      }
      this.loading = false;
    },

    // 保存自定义表格列数据
    async saveEmplPerforAnalyse() {
      try {
        let { errcode } = await Report.saveEmplPerforAnalyse({
          Heads: this.heads,
        });
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.fetchEmplPerforAnalyse();
          this.queryEmplPerforAnalyse();
        }
      } catch (e) {
        //TODO handle the exception
      }
    },

    // 门店改变事件
    onChangeStore(event) {
      let storeList = JSON.parse(JSON.stringify(this.storeList));
      let copyStoreList = JSON.parse(JSON.stringify(this.copyStoreList));
      let emplGuids = JSON.parse(JSON.stringify(this.submitData.EmplGuids));

      if (event.length == 0) {
        storeList = copyStoreList;
      } else {
        storeList.forEach((item, index) => {
          for (let i = 0; i < event.length; i++) {
            if (event[i] == item.StoresGuid) {
              storeList[index].isSelected = true;
              break;
            } else if (item.activeNumber) {
              storeList[index] = copyStoreList[index];
              break;
            }
            storeList[index].isSelected = false;
          }
        });
      }
      // this.submitData.EmplGuids = emplGuids;
      // this.storeList = storeList;
      this.$refs.DfEmplSelect.onSelectedEmpl({ storeList });
    },

    // 员工选中事件
    onSelectedEmpl(event) {
      // console.log(event);
      this.storeList = event.storeList;
      this.submitData.EmplGuids = event.emplGuids;
    },

     // 初始化日期状态
     async initDateState() {
      let { beginDay, endDay, beginMonth, endMonth } = await this.$store.dispatch(
        "onGetDateState"
      );
      this.dataArray = [beginDay, endDay];
      this.dayArray = [beginDay, endDay];
      this.monthArray = [beginMonth, endMonth];
      this.onChangeRadio(this.submitData.QueryMode);
    },

    // 改变日期状态
    onChangeDate(event) {
      this.$store.dispatch(
        "onChangeDateState",
        this.submitData.QueryMode == 2
          ? { monthTime: event }
          : { dayTime: event }
      );
      this.initDateState();
    },

    // 单选框改变事件
    onChangeRadio(event) {
      let arr = [];
      if (event == 1) {
        arr = this.dataArray;
      } else if (event == 2) {
        arr = this.monthArray;
      } else if (event == 3) {
        arr = this.dayArray;
      }

      this.submitData.BeginDate = arr[0];
      this.submitData.EndDate = arr[1];
    },

    // 打开自定义列对话框
    onCustomTable() {
      this.$refs.customDialog.onShowDialog(this.headData);
    },

    // 保存确认按钮点击事件
    onSubmitRevenue(event) {
      let { Heads } = event;
      this.heads = Heads;
      this.saveEmplPerforAnalyse();
    },

    // 查询按钮点击事件
    onSearchData() {
      this.queryEmplPerforAnalyse();
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "员工业绩分析表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },

    // 打印数据
    onPrintTable() {
      let table = document.querySelector(".print").innerHTML;
      let div = document.createElement("div");
      div.id = "tTable";
      div.innerHTML = table;
      div.querySelector(".hidden-columns").remove();
      div.querySelector(".el-table__header-wrapper").remove();
      div.querySelector(".el-table__footer-wrapper").remove();
      div.querySelector(".el-table__fixed").remove();
      div.querySelector(".el-table__column-resize-proxy").remove();
      div.querySelector(".el-loading-mask").remove();
      let block = div.querySelector(".el-table__empty-block");
      if (block) {
        block.remove();
      }
      let tbody = div.querySelector(".el-table__body-wrapper table tbody");

      // 表头
      let group = document.querySelector(
        "#exprot-table .el-table__header-wrapper table .is-group"
      ).innerHTML;
      let thead = document.createElement("thead");
      thead.innerHTML = group;
      thead.className = "is-group";
      // console.log(thead);

      // 表尾
      let gutter = document.querySelector(
        "#exprot-table .el-table__footer-wrapper table .has-gutter tr"
      ).innerHTML;
      let tr = document.createElement("tr");
      tr.innerHTML = gutter;
      tr.className = "el-table__row";

      tbody.before(thead);
      tbody.append(tr);

      document.querySelector(".print").appendChild(div);

      // this.isFixedRow = false;
      this.$nextTick(() => {
        printJS({
          printable: "tTable", //'exprot-table',
          type: "html",
          scanStyles: false,
          style: `table { border: 1px solid #333; } th{ border: 1px solid #333; margin: 5px 0; text-align: center; } td{ border: 1px solid #333; margin: 5px 0; text-align: center;}`, // 表格样式
        });

        div.remove();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.perfor-analyse {
  .df-row {
    display: flex;
    margin: 20px 0;
    .df-col {
      margin-right: 10px;
    }
  }

  // .el-tag {
  //   display: flex;
  //   align-items: center;
  //   .el-select__tags-text {
  //     display: inline-block;
  //     max-width: 85px;
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //   }
  // }
}

::v-deep .down-select {
  display: none !important;
}
</style>
